






















































































import Vue from 'vue'
import axios from 'axios'
import { Game, GameRound2 } from '@/types/Game'
import baseUrl from '@/config/Api'
import IntroVideo from './IntroVideo.vue'

export default Vue.extend({
	components: { IntroVideo },
	props: {
		game: {
			type: Object as () => Game,
			required: true,
		},
	},
	data() {
		return {
			intro: true,
			textIntro: false,
			loading: false,
			active: {} as { [key: string]: any },
			erase: false,
			points: undefined as number|undefined,
			gameRound: undefined as GameRound2|undefined,
			showQuestion: true,
			showCorrect: false,
			correct: null as any,
		}
	},
	methods: {
		start() {
			this.intro = false
			this.textIntro = false
			this.loading = true

			axios.get(
				`${baseUrl}/games/${this.game.id}/rounds/2`,
				{
					params: {
						token: this.game.token,
					},
				},
			).then((res) => {
				this.gameRound = res.data
				this.loading = false
				this.$emit('startTimer')
			}).catch((e) => {
				console.log(e)
				this.$emit('error')
			})
		},
		submit() {
			this.loading = true
			const selection: string[] = []
			Object.keys(this.active).forEach((k) => {
				if (this.active[k] === 1) {
					selection.push(k)
				}
			})
			axios.post(
				`${baseUrl}/games/${this.game.id}/rounds/2`,
				{ correct: selection },
				{ params: { token: this.game.token } },
			).then((res) => {
				this.$emit('pauseTimer')
				this.points = res.data.points
				this.correct = res.data.correct
				this.showCorrect = true
				this.loading = false
			}).catch((e) => {
				console.log(e)
				this.$emit('error')
			})
		},
		touch(e: any) {
			if (this.showCorrect) {
				return
			}

			const app = document.getElementById('app')

			const x = e.touches[0].clientX + app?.scrollTop
			const y = e.touches[0].clientY
			const items = document.querySelectorAll('.word-grid > div > div')
			const Break = {}

			try {
				items.forEach((itemTemp) => {
					const item = itemTemp as HTMLDivElement

					if (
						item.offsetLeft <= x
						&& item.offsetLeft + item.clientWidth >= x
						&& item.offsetTop < y
						&& item.offsetTop + item.clientHeight > y
					) {
						const id = item.getAttribute('data-id')
						if (id) {
							this.active[id] = this.erase ? 0 : 1
							this.$forceUpdate()
						}

						throw Break
					}
				})
			} catch (ex) {
				if (ex !== Break) throw ex
			}
		},
		itemClick(key: string) {
			if (this.showCorrect) {
				return
			}

			this.active[key] = this.active[key] === 1 ? 0 : 1
			this.$forceUpdate()
		},
	},
})










































































import Vue from 'vue'
import { Game, GameRound4 } from '@/types/Game'
import axios from 'axios'
import baseUrl from '@/config/Api'
import IntroVideo from './IntroVideo.vue'

export default Vue.extend({
	components: { IntroVideo },
	props: {
		game: {
			type: Object as () => Game,
			required: true,
		},
	},
	data() {
		return {
			intro: true,
			textIntro: false,
			loading: false,
			gameRound: undefined as GameRound4|undefined,
			points: undefined as number|undefined,
			estimation: {} as { [key: number]: number },
			correct: null as any,
		}
	},
	methods: {
		start() {
			this.intro = false
			this.textIntro = false
			this.loading = true

			axios.get(
				`${baseUrl}/games/${this.game.id}/rounds/4`,
				{
					params: {
						token: this.game.token,
					},
				},
			).then((res) => {
				this.gameRound = res.data
				this.loading = false
				this.$emit('startTimer')
			}).catch((e) => {
				console.log(e)
				this.$emit('error')
			})
		},
		submit() {
			this.loading = true
			axios.post(
				`${baseUrl}/games/${this.game.id}/rounds/4`,
				{ estimation: parseInt(Object.values(this.estimation).join(''), 10) },
				{ params: { token: this.game.token } },
			).then((res) => {
				this.$emit('pauseTimer')
				this.points = res.data.points
				this.correct = res.data.correct
				this.loading = false
			}).catch((e) => {
				console.log(e)
				this.$emit('error')
			})
		},
		nextInput(e: KeyboardEvent, current: number) {
			if (e.key === 'Backspace') {
				if (current > 1 && !this.estimation[current]) {
					// eslint-disable-next-line no-unused-expressions
					document.getElementById(`input-${current - 1}`)?.focus()
				}
				return
			}

			const next = document.getElementById(`input-${current + 1}`)
			if (next) {
				next.focus()
			} else {
				// eslint-disable-next-line no-unused-expressions
				document.getElementById(`input-${current}`)?.blur()
			}
		},
	},
})

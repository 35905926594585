

















































































import Vue from 'vue'
import { Game, GameRound5 } from '@/types/Game'
import axios from 'axios'
import Draggable from 'vuedraggable'
import baseUrl from '@/config/Api'
import IntroVideo from './IntroVideo.vue'

export default Vue.extend({
	components: { Draggable, IntroVideo },
	props: {
		game: {
			type: Object as () => Game,
			required: true,
		},
	},
	data() {
		return {
			intro: true,
			textIntro: false,
			loading: false,
			gameRound: undefined as GameRound5|undefined,
			points: undefined as number|undefined,
			showItems: false,
			selectedItems: [] as string[],
			correct: null,
			showCorrect: false,
		}
	},
	methods: {
		start() {
			this.intro = false
			this.textIntro = false
			this.loading = true

			axios.get(
				`${baseUrl}/games/${this.game.id}/rounds/5`,
				{
					params: {
						token: this.game.token,
					},
				},
			).then((res) => {
				this.gameRound = res.data
				this.loading = false
				this.$emit('startTimer')
			}).catch((e) => {
				console.log(e)
				this.$emit('error')
			})
		},
		submit() {
			if (this.showCorrect) {
				this.showCorrect = false
				return
			}

			this.loading = true

			axios.post(
				`${baseUrl}/games/${this.game.id}/rounds/5`,
				this.gameRound?.type === 'multipleChoice' ? {
					selected: this.selectedItems,
				} : {
					items: this.gameRound?.items,
				},
				{ params: { token: this.game.token } },
			).then((res) => {
				this.$emit('pauseTimer')
				if (this.gameRound?.type === 'multipleChoice') {
					this.showCorrect = true
				}
				this.points = res.data.points
				this.correct = res.data.correct
				this.loading = false
			}).catch((e) => {
				console.log(e)
				this.$emit('error')
			})
		},
		toggle(item: string) {
			if (this.showCorrect) {
				return
			}

			if (this.selectedItems.includes(item)) {
				this.selectedItems.splice(this.selectedItems.indexOf(item), 1)
			} else {
				this.selectedItems.push(item)
			}
		},
	},
})























































































































import Vue from 'vue'
import axios from 'axios'

import { Game } from '@/types/Game'
import baseUrl from '@/config/Api'

import IntroVideo from './IntroVideo.vue'

export default Vue.extend({
	components: { IntroVideo },
	props: {
		game: {
			type: Object as () => Game,
			required: true,
		},
	},
	data() {
		return {
			outro: true,
			step: 1,
			showForm: false,
			submitting: false,
			loading: false,
			details: {
				firstName: '',
				lastName: '',
				email: '',
			},
			instantPrize: undefined as {
				id: number,
				url?: string,
				voucher?: string,
			}|undefined,
			codeCopied: false,
			pinCopied: false,
		}
	},
	methods: {
		signup() {
			this.submitting = true
			this.loading = true

			axios.post(`${baseUrl}/games/${this.game.id}/signup`, {
				firstName: this.details.firstName,
				lastName: this.details.lastName,
				email: this.details.email,
			}, {
				params: {
					token: this.game.token,
				},
			}).then(() => {
				this.step += 1

				axios.get(`${baseUrl}/games/${this.game.id}/instant-prize`, { params: { token: this.game.token } })
					.then((res) => {
						this.instantPrize = res.data
						this.loading = false
					})
					.catch((e) => {
						console.log(e)
						this.loading = false
						this.$emit('error')
					})
			}).catch((e) => {
				if (e.response.data && e.response.data.status === 'alreadySignedUp') {
					// eslint-disable-next-line no-alert
					alert('Du hast Dich bereits für den Hauptgewinn angemeldet.')
					this.step += 1
				} else {
					// eslint-disable-next-line no-alert, max-len
					alert('Leider ist ein Fehler bei der Übertragung aufgetreten. Bitte versuche es erneut und überprüfe Deine Eingaben.')
				}
				console.log(e)
				this.loading = false
				this.submitting = false
			})
		},
		goToInstantPrize() {
			if (!this.instantPrize?.url) {
				return
			}

			window.location.href = this.instantPrize.url
		},
		copy(text: string, pin = false) {
			navigator.clipboard.writeText(text).then(() => {
				if (pin) {
					this.pinCopied = true
				} else {
					this.codeCopied = true
				}
			}).catch(() => {
				// eslint-disable-next-line no-alert
				alert('Leider konnte der Code nicht in Ihre Zwischenablage kopiert werden.')
			})
		},
	},
})





























import Vue from 'vue'

export default Vue.extend({
	props: {
		video: {
			type: String,
			required: true,
		},
		autoplay: {
			type: Boolean,
			default: true,
		},
		outro: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			introPlaying: false,
		}
	},
})

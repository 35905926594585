

















































































import Vue from 'vue'
import axios from 'axios'
import baseUrl from '@/config/Api'

import { Game } from '@/types/Game'
import IntroVideo from './IntroVideo.vue'

export default Vue.extend({
	components: { IntroVideo },
	props: {
		game: {
			type: Object as () => Game,
			required: true,
		},
	},
	data() {
		return {
			outro: true,
			loading: false,
			prize: undefined as {
				id: number,
				url: string,
			}|undefined,
			codeCopied: false,
			pinCopied: false,
		}
	},
	computed: {
		percentage(): number {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			if (!this.game.totalPoints) {
				return 0
			}

			return Math.round((this.game.totalPoints / 500) * 100)
		},
	},
	mounted() {
		this.loading = true

		axios.get(`${baseUrl}/games/${this.game.id}/instant-prize`, { params: { token: this.game.token } })
			.then((res) => {
				this.prize = res.data
				this.loading = false
			})
	},
	methods: {
		goToInstantPrize() {
			if (!this.prize) {
				return
			}

			window.location.href = this.prize.url
		},
		copy(text: string, pin = false) {
			navigator.clipboard.writeText(text).then(() => {
				if (pin) {
					this.pinCopied = true
				} else {
					this.codeCopied = true
				}
			}).catch(() => {
				// eslint-disable-next-line no-alert
				alert('Leider konnte der Code nicht in Ihre Zwischenablage kopiert werden.')
			})
		},
	},
})

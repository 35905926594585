


































































import Vue from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MarqueeText from 'vue-marquee-text-component'

export default Vue.extend({
	components: { MarqueeText },
	props: {
		secondsSpent: {
			type: Number,
			default: 0,
		},
		round: {
			type: Object,
			default: null,
		},
	},
})
